<template>
  <div class="w-full h-full flex justify-center items-center flex-col p-4 relative overflow-hidden rounded-md sm:pt-4 pt-12">
    <div class="flex justify-end items-center flex-grow relative w-full  sm:flex-row flex-col-reverse">
      <div class="text-right text-turquoise-500 text-2xl font-light w-full">
        <h2 class="mb-2">24 hours</h2>
        <h2 class="flex justify-end items-center flex-shrink-0 flex-nowrap">
          <div class="whitespace-nowrap">No Question Asked</div>
          <span class="bg-turquoise-500 text-turquoise-1000 p-1 px-2 rounded-md ml-1 font-brand font-bold">Refund</span>
        </h2>
      </div>

      <div class="sm:pl-8 sm:pr-4 pl-4 pr-2 flex justify-center items-center relative sm:-mb-8 -mb-4">
        <img class="w-[150px] h-[150px] object-contain rotate-12 " src="/shield.png" alt="Oxbull's shield image illustrates an investor-friendly refund policy, granting immunity for refunds without questions asked from token generation." />

        <div class="flex justify-center items-center absolute">
          <div v-for="i in 10" class="pingcircle w-[20rem] h-[20rem] rounded-full bg-turquoise-200/5 absolute border border-white/5 flex-shrink-0"></div>
        </div>
        <!-- <div class="w-[36rem] h-[36rem] rounded-full bg-turquoise-200/5 absolute border border-white/10"></div> -->
        <!-- <div class="w-[54rem] h-[54rem] rounded-full bg-turquoise-200/5 absolute border border-white/10"></div> -->
      </div>
    </div>

    <div class="w-full z-20 mt-8 sm:mt-0">
      <div class="mb-2 w-full flex justify-between items-center">
        <h2 class="text-xl font-bold font-brand text-white ">Oxbull Immunity</h2>

        <a href="https://docs.oxbull.tech/oxbull-immunity-refund-policy" target="_blank" class="sm:hidden block btn bg-white/20 rounded-full text-white font-brand font-bold border-none py-2 px-3 min-h-0 h-auto backdrop-blur-sm">Details</a>

      </div>
      <div class="w-full flex justify-between items-start">
        <p class="text-zinc-400 text-sm" >
Investors have 24 hours from Token Generation to request a refund. Please read the full refund policy for more details.</p>
        <a href="https://docs.oxbull.tech/oxbull-immunity-refund-policy" target="_blank" class="hidden sm:flex bg-white/20 rounded-full text-white font-brand font-bold border-none px-8 backdrop-blur-sm h-auto btn mr-4 justify-center items-center">Details</a>
      </div>
    </div>

  </div>
</template>

<script setup>

  const { $anime } = useNuxtApp()

  onMounted( () => {

    $anime({
      targets: '.pingcircle',
      delay: $anime.stagger(400),
      easing: 'linear',
      loop: true,
      opacity:[
        {value:0, duration:0},
        {value:1, duration:3000},
        {value:0, duration:3000},
      ],
      scale: [
        {value:0, duration:0},
        {value:5, duration:6000},
      ]
    });

  })

</script>

<style></style>
