<template>
  <div class="w-full h-full flex flex-col justify-center items-center relative overflow-hidden">
    
    <p class="text-2xl font-bold font-brand text-turquoise-500 " >Stake & Earn</p>

    <div class="flex flex-col w-full justify-start items-center">

      <div class="text-center" >
        <p class="text-[2.5rem] font-bold font-brand text-white " >2.5% APR</p>
        <!-- <p class="text-zinc-400" >That's about 2 OXI per day for Tier One.</p> -->
      </div>

    </div>

  </div>
</template>

<script setup>

</script>

<style scoped>

.text-outline {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff50;
  color: #00000000;
}

</style>